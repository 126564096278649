<script setup lang="ts">
  import { PlanType } from '~/generated/api-clients-generated'
  import { EngineStore } from '~/stores/engine'
  import type { QuotedPlanVm } from '~/models/quoting/QuotedPlan'
  import { PlanCheckupStore } from '~/stores/planCheckup'

  const props = defineProps<{
    id: string,
    quote: QuotedPlanVm
  }>()

  const { getId } = Id.use(props.id)

  const { flag } = FeatureFlags.use()

  const engine = EngineStore.use()
  const checkupStore = PlanCheckupStore.use()
  const emptyState = computed(() => engine.isEmptyState)

  const { quotingConfig } = QuotingConfig.use()
  const { currentSort } = QuoteUtils.useSort()

  const hideQuoteScoreThreshold = computed(
    () => flag<number>('displayed-quote-score-threshold').value
  )

  const showNeedsSection = computed(
    () =>
      quotingConfig.value.emptyAddNeeds ||
      (props.quote.type === PlanType.MAPD && props.quote.providerCoverage.count > 0) ||
      props.quote.drugCoverage.count > 0 ||
      !_isNil(props.quote.pharmacyCoverage.first) ||
      (props.quote.type === PlanType.MAPD && props.quote.additionalBenefits.count > 0)
  )

  const showCosts = computed(() => true)

  const showPlanTags = computed(() => !emptyState.value
    && !(currentSort.value === 'healthpilot_rec'
      && props.quote.recommendation.exists
      && props.quote.score < hideQuoteScoreThreshold.value
      && props.quote.medicareId !== checkupStore?.currentCheckup?.currentPlanId
    ))
</script>

<template>
  <article class="quote-card" :id="getId()">
    <header>
      <slot name="header" v-bind="{ getId }">

      </slot>
    </header>
    <main class="card-content">
      <slot name="default" v-bind="{ getId, showNeedsSection, showCosts, showPlanTags }">

      </slot>
    </main>
    <footer>
      <slot name="footer" v-bind="{ getId }">

      </slot>
    </footer>
  </article>
</template>

<style>
  .quote-card {
    @apply flex h-full w-full flex-col rounded-quote border border-border bg-white;

    main.card-content {
      @apply mb-4 flex grow flex-col justify-end space-y-4 px-6;

      section {
        @apply flex flex-col;

        header.content-section-header {
          @apply flex w-full items-center justify-between rounded-heading p-2.5;

          div {
            @apply flex items-center space-x-2 text-primary-900;

            h2 {
              @apply text-2xl;
            }
          }
        }

        .content-section-data {
          @apply flex flex-col space-y-4 px-4 py-4 md:px-12;

          .content-row {
            @apply flex w-full flex-col justify-between md:flex-row md:items-center;

            dt {
              @apply text-lg text-primary-900;
            }

            dl {
              @apply text-xl font-bold text-primary-900;
            }
          }
        }
      }
    }

    & > footer {
      @apply flex w-full items-center justify-between border-t border-border-light px-6 py-2;
    }
  }
</style>