<script setup lang="ts">
  import type { QuotedPlanVm } from '~/models/quoting/QuotedPlan'
  import { EngineStore } from '~/stores/engine'
  import { ProfileStore } from '~/stores/profile'

  const props = defineProps<{
    id: string,
    quote: QuotedPlanVm
  }>()

  const engine = EngineStore.use()
  const profile = ProfileStore.use()
  const emptyState = computed(() => engine.isEmptyState)

  const { hydratedTags } = await PlanTags.use()
  const { getComponentContent, hydratePlaceholders } = Cms.use()
  const { content } = await getComponentContent('QuoteCard')

  const tags = computed(() => _compact(props.quote.planAttributes.map(attr => hydratedTags.value[attr])))
</script>

<template>
  <section v-if="!emptyState && !_isNil(tags)" class="grow">
    <header class="content-section-header bg-primary-100">
      <div>
        <HpIcon name="heart" class="text-primary-600" />
        <h2>Why this plan fits you</h2>
      </div>
    </header>
    <ul class="quote-fit-tags" v-if="tags.length > 0">
      <li v-for="tag in tags">
        <HpIcon name="check" class="text-success" />
        <span>{{ tag }}</span>
      </li>
    </ul>
    <div
      v-else
      class="text-text-light p-4 text-center leading-tight text-gray-600"
      v-html="
            hydratePlaceholders(content.ext.empty_plan_fit_text, {
              countyName: profile.location.countyName
            })
          "
    ></div>
  </section>
</template>

<style scoped>

</style>